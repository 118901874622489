<template>
  <div>
    <a-card>
      <label>Activate Semester</label>
      <template v-if="!dataSchoolYear.status || dataSchoolYear.status === 'inaktif'">
        <div class="my-3">
          <a-button size="large" type="primary" @click.prevent="setAsActive">Set Active Now</a-button>
        </div>
      </template>
      <template v-else>
        <div class="my-3">
          <a-button size="large" disabled>Already Active</a-button>
        </div>
      </template>
    </a-card>
    <br />
  </div>
</template>

<script>
export default {
  props: {
    dataSchoolYear: {
      type: Object,
    },
  },
  methods: {
    async setAsActive() {
      try {
        const idSchoolYear = this.$route.params.id
        this.$confirm({
          title: 'Warning',
          content: (
            <div>Are you sure want to activate this study School Year?</div>
          ),
          onOk: async () => {
            try {
              const res = await this.$store.dispatch('admin/UPDATE_STATUS_TAHUN_AJARAN', { id: idSchoolYear })
              if (res === 'SUCCESS_UPDATE_STATUS_TAHUN_AJARAN') {
                this.$notification.success({
                  message: 'Success.',
                  description: 'School Year has been activated',
                })
              } else {
                this.$notification.error({
                  message: 'Error.',
                  description: 'School Year has not been activated',
                })
              }
              this.$emit('fetchDetail')
              this.$router.push({ name: 'Master Admin' })
            } catch (err) {
              console.log(err)
            }
          },
          onCancel: () => {
            this.loadingDeleteSchoolYear = false
          },
          centered: true,
          icon: 'warning',
          okType: 'primary',
          okText: 'Set Now',
          cancelText: 'Cancel',
        })
      } catch (err) {
        console.log(err)
      }
    },
    // async deleteNow() {
    //   const idSchoolYear = this.$route.params.id
    //   this.$confirm({
    //     title: 'Warning',
    //     content: (
    //       <div>Are you sure want to delete this study School Year?</div>
    //     ),
    //     onOk: () => {
    //       this.$store.dispatch('master/DELETE_SCHOOL_YEAR', {
    //         id: idSchoolYear,
    //       })
    //         .then(isSuccess => {
    //           this.loadingDeleteSchoolYear = false
    //           if (isSuccess) {
    //             this.$notification.success({
    //               message: 'Success',
    //               description:
    //                 'Study School Year has been deleted',
    //             })
    //             this.$router.push({ name: 'Master Admin' })
    //           } else {
    //             this.$notification.error({
    //               message: 'Failed',
    //               description: 'Study School Year has not been deleted',
    //             })
    //           }
    //         })
    //     },
    //     onCancel: () => {
    //       this.loadingDeleteSchoolYear = false
    //     },
    //     centered: true,
    //     icon: 'warning',
    //     okType: 'danger',
    //     okText: 'Delete',
    //     cancelText: 'Cancel',
    //   })
    // },
  },
}
</script>

<style>

</style>
